import React, { useState } from 'react';
import './ContactForm.css'; 
function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send data to a server)
    console.log('Form submitted', formData);
  };

  return (
    <div className="contact_us_2 mt-top">
      <div className="responsive-container-block big-container">
        <div className="blueBG"></div>
        <div className="responsive-container-block container">
          <form className="form-box" onSubmit={handleSubmit}>
            <div className="container-block form-wrapper">
              <p className="text-blk contactus-head">Get in Touch</p>
              <p className="text-blk contactus-subhead">Contact Us for more Quries </p>
              <div className="responsive-container-block">
                <div className="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6" id="i10mt">
                  <p className="text-blk input-title">FIRST NAME</p>
                  <input
                    className="input"
                    name="firstName"
                    placeholder="Please enter first name..."
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                  <p className="text-blk input-title">LAST NAME</p>
                  <input
                    className="input"
                    name="lastName"
                    placeholder="Please enter last name..."
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>
                <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                  <p className="text-blk input-title">EMAIL</p>
                  <input
                    className="input"
                    name="email"
                    type="email"
                    placeholder="Please enter email..."
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                  <p className="text-blk input-title">PHONE NUMBER</p>
                  <input
                    className="input"
                    name="phoneNumber"
                    placeholder="Please enter phone number..."
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="i634i">
                  <p className="text-blk input-title">WHAT DO YOU HAVE IN MIND</p>
                  <textarea
                    className="textinput"
                    name="message"
                    placeholder="Please enter query..."
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button className="submit-btn" type="submit">
                Submit
              </button>
            </div>
            <div className="social-media-links">
              <a href="#" id="ix94i-2">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-twitter.png" alt="Twitter" />
              </a>
              <a href="#">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-facebook.png" alt="Facebook" />
              </a>
              <a href="#">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-google.png" alt="Google" />
              </a>
              <a href="#" id="izldf-2">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-instagram.png" alt="Instagram" />
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
