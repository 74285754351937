import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import './Navbar.scss';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // Scroll listener to detect when user scrolls down
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`navbar-wrapper ${isScrolled ? 'scrolled' : ''}`}>
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="navbar-container">
            <NavLink className="navbar-brand" to="/">
              <img src="Images/logo.svg" alt="logo" className="navbar-logo" />
            </NavLink>

            <div className="d-lg-none d-flex align-items-center">
              <i className="fas fa-user profile-icon"></i>

              <button
                className="navbar-toggler"
                type="button"
                onClick={handleToggle}
                aria-controls="navbarTogglerDemo01"
                aria-expanded={isOpen ? 'true' : 'false'}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>

          <div className={`navbar-content collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarTogglerDemo01">
            <ul className="navbar-nav">
              <li className="nav-item ">
                <NavLink className="nav-link" to="/" activeClassName="active">Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/products" activeClassName="active">Products</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/aboutUs" activeClassName="active">About Us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/blog" activeClassName="active">Blog</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link last" to="/contactUs" activeClassName="active">Contact</NavLink>
              </li>
            </ul>
          </div>

          <div className="d-none d-lg-flex align-items-center">
            <NavLink to="/profile" className="profile-icon">
              <i className="fas fa-user"></i>
            </NavLink>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
