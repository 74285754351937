import React from 'react';
import './HomeCards.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';

const cardsData = [
  {
    id: 1,
    image: 'Images/img1.jpg', 
    title: 'Product 1',
    description: 'This is the description for Product 1.',
    rating: 4
  },
  {
    id: 2,
    image: 'Images/img2.jpg',
    title: 'Product 2',
    description: 'This is the description for Product 2.',
    rating: 5
  },
  {
    id: 3,
    image: 'Images/img3.jpg',
    title: 'Product 3',
    description: 'This is the description for Product 3.',
    rating: 3
  },
  {
    id: 4,
    image: 'Images/img4.jpg',
    title: 'Product 4',
    description: 'This is the description for Product 4.',
    rating: 3
  }
];

function HomeCards() {
  return (
    <div className="container">
      <div className="row">
        {cardsData.map(card => (
          <div key={card.id} className="col-md-3 col-sm-6">
            <div className="card h-100 animated-card">
              <img src={card.image} className="card-img-top" alt={card.title} />
              <div className="card-body">
                <h5 className="card-title">{card.title}</h5>
                <p className="card-text">{card.description}</p>
                <div className="card-rating">
                  {Array(card.rating).fill().map((_, i) => (
                    <span key={i} className="star">&#9733;</span> // Star character
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomeCards;
