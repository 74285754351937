import React from 'react'
import WhyWeAre from '../../WhyWeAre/Index'
import WhyChooseUs from '../../WhyChooseUs/Index'
import AboutMain from '../../AboutMain/Index'

function AboutUs() {
  return (
    <div>
    <AboutMain/>
     <WhyWeAre/>
     <WhyChooseUs/>
    </div>
  )
}

export default AboutUs
