import React from 'react';
import './Biography.css';

function Biography() {
  return (
    <div>
      <div className="container-fluid">
        <div className="row align-items-center">
          
          {/* Image Column */}
          <div className="col-sm-6 img-col d-flex justify-content-center">
            <img className="img-bio my-5 border" src="Images/img6.jpg" alt="Hero" />
          </div>
          
          {/* Text Column */}
          <div className="col-sm-6 text-col p-5">
            <h6 className="h5-hero">BIOGRAPHY</h6>
            <h1 className="h1-hero">John Roberts​</h1>
            <h3 className="my-4">The Descendant of Hope​ is the key of success</h3>
            <p className="p-hero">
              Justo habitant at augue ac sed proin consectetur ac urna nisl elit <br />
              nulla facilisis viverra dolor sagittis nisi risus egestas adipiscing <br />
              nibh euismod.
            </p>
            <div className="my-5">
              <button className="btn-hero">Read More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Biography;
