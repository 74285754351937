import React from 'react';

function Blog() {
  // Place the blogPosts array inside or outside the component as needed
  const blogPosts = [
    {
      id: 1,
      category: 'Living',
      title: 'How to Use Facial Expressions in Public Speaking',
      date: '11 Dec 2030',
      comments: 383,
      imageUrl: 'Images/img5.jpg',
      altText: 'Living',
      link: '#!',
    },
    {
      id: 2,
      category: 'Travel',
      title: 'Exploring the Mountains: A Journey to Remember',
      date: '05 Jan 2031',
      comments: 250,
      imageUrl: 'Images/img6.jpg',

      altText: 'Travel',
      link: '#!',
    },
    {
      id: 3,
      category: 'Technology',
      title: 'The Rise of AI in Everyday Life',
      date: '22 Feb 2031',
      comments: 420,
      imageUrl: 'Images/img7.jpg',

      altText: 'Technology',
      link: '#!',
    },
    {
        id: 1,
        category: 'Living',
        title: 'How to Use Facial Expressions in Public Speaking',
        date: '11 Dec 2030',
        comments: 383,
        imageUrl: 'Images/img5.jpg',
        altText: 'Living',
        link: '#!',
      },
      {
        id: 2,
        category: 'Travel',
        title: 'Exploring the Mountains: A Journey to Remember',
        date: '05 Jan 2031',
        comments: 250,
        imageUrl: 'Images/img6.jpg',
  
        altText: 'Travel',
        link: '#!',
      },
      {
        id: 3,
        category: 'Technology',
        title: 'The Rise of AI in Everyday Life',
        date: '22 Feb 2031',
        comments: 420,
        imageUrl: 'Images/img7.jpg',
  
        altText: 'Technology',
        link: '#!',
      },
  ];

  return (
    <div>
      <section className="bsb-blog-5 py-3 py-md-5 py-xl-8 my-5">
        <div className="container">
          <div className="row justify-content-md-center ">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="display-5 mb-4 mb-md-5 text-center">Latest Articles</h2>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container overflow-hidden">
          <div className="row gy-4 gy-md-5 gx-xl-6 gy-xl-6 gx-xxl-9 gy-xxl-9">
            {blogPosts.map((post) => (
              <div className="col-12 col-lg-4" key={post.id}>
                <article>
                  <div className="card border-0 bg-transparent">
                    <figure className="card-img-top mb-4 overflow-hidden bsb-overlay-hover">
                      <a href={post.link}>
                        <img
                          className="img-fluid bsb-scale bsb-hover-scale-up"
                          loading="lazy"
                          src={post.imageUrl}
                          alt={post.altText}
                        />
                      </a>
                      <figcaption>
                      
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          className="bi bi-eye text-white bsb-hover-fadeInLeft"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8z" />
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5z" />
                        </svg>
                        <h4 className="h6 text-white bsb-hover-fadeInRight mt-2">Read More</h4>
                      </figcaption>
                    </figure>
                    <div className="card-body m-0 p-0">
                      <div className="entry-header mb-3">
                        <ul className="entry-meta list-unstyled d-flex mb-3">
                          <li>
                            <a
                              className="d-inline-flex px-2 py-1 link-primary text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-2 text-decoration-none fs-7"
                              href="#!"
                            >
                              {post.category}
                            </a>
                          </li>
                        </ul>
                        <h2 className="card-title entry-title h4 m-0">
                          <a className="link-dark text-decoration-none" href={post.link}>
                            {post.title}
                          </a>
                        </h2>
                      </div>
                    </div>
                    <div className="card-footer border-0 bg-transparent p-0 m-0">
                      <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                        <li>
                          <a
                            className="fs-7 link-secondary text-decoration-none d-flex align-items-center"
                            href="#!"
                          >
                            {/* Calendar Icon */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="currentColor"
                              className="bi bi-calendar3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                              <path d="M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                            </svg>
                            <span className="ms-2 fs-7 mx-5"
      style={{ marginLeft: '100px' }}>
  {post.date}
</span>

                          </a>
                        </li>
                        <li>
                          <span className="px-3">&bull;</span>
                        </li>
                        <li>
                          <a
                            className="link-secondary text-decoration-none d-flex align-items-center"
                            href="#!"
                          >
                            {/* Comments Icon */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="currentColor"
                              className="bi bi-chat-dots"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                              <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125z" />
                            </svg>
                            <span className="ms-2 fs-7">{post.comments}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>

       
      </section>
    </div>
  );
}

export default Blog;
