import React from 'react'
import HeroSection from '../../Hero/Index'



import HomeCounter from '../../HomeCounter/Index';
import HomeCards from '../../HomeCards/Index';
import YoutubeVideos from '../../YouTubeVideos​/Index';
import Biography from '../../Biography/Index';
import LatestYouTube from '../../LatestYouTube/Index';
import FaqHome from '../../FaqHome/Index';



function Home() {
  return (
    <div>
<HeroSection/>
<Biography/>
<HomeCards/>
<LatestYouTube/>
<YoutubeVideos/>
<HomeCounter/>
<FaqHome/>
    </div>
  )
}

export default Home
