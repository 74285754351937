import React from 'react';
import { FaPhoneAlt, FaMapMarkerAlt, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import './ContactHome.css'; // Assuming you are using SCSS
import ContactForm from '../ContactFrom/Index';

function ContactHome() {
  return (
    <div className="contact-home ">
      {/* <div className="container">
        <div className="row">
          <div className="col-sm-4 ">
            <div className="social-icons">
              <a href="https://facebook.com" aria-label="Facebook"><FaFacebook /></a>
              <a href="https://twitter.com" aria-label="Twitter"><FaTwitter /></a>
              <a href="https://instagram.com" aria-label="Instagram"><FaInstagram /></a>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="mobile-number">
              <FaPhoneAlt /> +1234567890
            </div>
          </div>
          <div className="col-sm-4">
            <div className="location">
              <FaMapMarkerAlt /> 1234 Street Name, City, Country
            </div>
          </div>
      <ContactForm/>

        </div>
      </div> */}
      <ContactForm/>
    </div>
  );
}

export default ContactHome;
