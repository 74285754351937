import React from 'react';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='section-hero'>
      <div className="container-fluid">
        <div className="row align-items-center">
       
          <div className="col-sm-6 p-5 ">
            <h6 className="h5-hero">NEW RELEASE</h6>
            <h1 className="h1-hero my-5">The Sons of the Empire</h1>
            <h3 className=" my-5">The Descendant of Hope​ is the key of success</h3>

            <p className="p-hero">
              Justo habitant at augue ac sed proin consectetur ac urna nisl elit <br />
              nulla facilisis viverra dolor sagittis nisi risus egestas adipiscing <br />
              nibh euismod.
            </p>
            <button className="btn-hero">Buy Now</button>
            <button className="btn-hero2">Read More</button>
          </div>

          {/* Image Column */}
          <div className="col-sm-6 d-flex justify-content-center">
            <img className="img-hero my-5" src="Images/img1.jpg" alt="Hero" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
