import React, { useState, useEffect, useRef } from 'react';
import CountUp from 'react-countup';
import './HomeCounter.css'; // Ensure to add necessary CSS for styling

function HomeCounter() {
  const [inView, setInView] = useState(false);
  const [hasCounted, setHasCounted] = useState(false); // Tracks if the counters have already run
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !hasCounted) {
          setInView(true);
          setHasCounted(true); // Ensure counters only run once
        }
      },
      { threshold: 0.5 } // Adjust threshold for when to start counting
    );

    if (counterRef.current) {
      observer.observe(counterRef.current); // Start observing the component
    }

    // Clean up observer when the component is unmounted
    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, [hasCounted]); // Only trigger when `hasCounted` is false

  return (
    <div className="container my-5 p-5" ref={counterRef}>
      <div className="row">
        <div className="col text-center">
          <h2>Counter</h2>
          <p>Counter to count up to a target number</p>
        </div>
      </div>
      
      <div className="row text-center">
        <div className="col">
          <div className="counter">
            <i className="fa fa-code fa-2x"></i>
            <h2 className="timer count-title count-number">
              {inView && <CountUp start={0} end={100} duration={2.5} />}
            </h2>
            <p className="count-text">Our Customer</p>
          </div>
        </div>
        <div className="col">
          <div className="counter">
            <i className="fa fa-coffee fa-2x"></i>
            <h2 className="timer count-title count-number">
              {inView && <CountUp start={0} end={1700} duration={2.5} />}
            </h2>
            <p className="count-text">Happy Clients</p>
          </div>
        </div>
        <div className="col">
          <div className="counter">
            <i className="fa fa-tasks fa-2x"></i> {/* Changed icon to fa-tasks */}
            <h2 className="timer count-title count-number">
              {inView && <CountUp start={0} end={11900} duration={2.5} />}
            </h2>
            <p className="count-text">Project Complete</p>
          </div>
        </div>
        <div className="col">
          <div className="counter">
            <i className="fa fa-bug fa-2x"></i>
            <h2 className="timer count-title count-number">
              {inView && <CountUp start={0} end={157} duration={2.5} />}
            </h2>
            <p className="count-text">Coffee With Clients</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeCounter;
