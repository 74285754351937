import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

function LatestYouTube() {
  return (
    <div>
      <section className='my-5'
        style={{
          position: 'relative',
          backgroundImage: "url('Images/youtube.jpg')", // Replace with your image path
          backgroundSize: 'cover', // Makes sure the image covers the entire section
          backgroundPosition: 'center', // Centers the background image
          height: '100vh', // Full viewport height
          width: '100%', // Full width
          overflow: 'hidden', // Ensures no overflow
        }}
      >
        {/* Blur Overlay */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(8px)', // Blur effect
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark transparent overlay
            zIndex: 1, // Ensures the overlay is above the background image
          }}
        ></div>

        {/* Content */}
        <div className="container h-100" style={{ position: 'relative', zIndex: 2 }}>
          <div className="row h-100 align-items-center">
            <div className="col-sm-6 offset-sm-6 text-white">
            
              <div className="d-flex align-items-center mb-3">
                <i className="fab fa-youtube" style={{ fontSize: '40px', color: 'red', marginRight: '10px' }}></i>
                <h5 className="mb-0">Latest On YouTube</h5>
              </div>

              <h2 style={{ fontFamily: 'Cambria, Cochin, Georgia, Times, "Times New Roman", serif', fontWeight: '600' , fontSize:'45px' }}>
  The Descendant of Hope​
</h2>
              <p className='my-4'>
                Tellus tellus mattis pulvinar nulla euismod fermentum rhoncus sed vestibulum 
                neque praesent pharetra ut fames viverra suscipit gravida dictumst volutpat 
                ullamcorper lacus.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LatestYouTube;
