import React from 'react';

const AboutMain = () => {
  return (
    <section className="bg-primary "
     style={{  marginTop: '105px' }}>
      <div className="container-fluid overflow-hidden">
        <div className="row">
          {/* Text Section */}
          <div className="col-12 col-md-6 order-1 order-md-0 align-self-md-end">
            <div className="row py-3 py-sm-5 py-xl-9 mt-md-10 justify-content-sm-center">
              <div className="col-12 col-sm-10">
              <h6 className="display-7 fw-bolder mb-4 text-white">About Us</h6>
                <h2 className="display-2 fw-bolder mb-4 text-white">We Offer Modern Web Solutions</h2>
                <div className="row">
                  <div className="col-12 col-xxl-8">
                    <p className="fs-5 mb-5 text-white">
                      We work in a customized way by devising and handling entirely online projects with long-term partnerships.
                    </p>
                  </div>
                </div>
                <div className="d-grid gap-2 d-sm-flex">
                  <button type="button" className="btn btn-warning bsb-btn-2xl rounded-pill">Explore Now</button>
                </div>
              </div>
            </div>
          </div>

          {/* Image Section */}
          <div className="col-12 col-md-6 p-0">
            <img
              className="img-fluid w-100 h-100 object-fit-cover"
              style={{ width: '400px', height: '350px', }}
              loading="lazy"
              src="Images/img8.jpg"
              alt="We Offer Modern Web Solutions"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMain;
