import React from 'react';
import { FaTwitter, FaFacebookF, FaInstagram } from 'react-icons/fa';
import './Footer.css'; 

function Footer() {
  return (
    <footer className="footer-07 bg-dark">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          {/* Brand/Logo on the left */}
          <div className="col-md-4 text-left">
            <h2 className="footer-heading">
             <img src="Images/logo-white.svg" className='text-white' alt="" />
            </h2>
          </div>

          {/* Menu in the center */}
          <div className="col-md-4 text-start">
            <p className="menu">
              <a href="#">Home</a>
              <a href="#">Agent</a>
              <a href="#">About</a>
              <a href="#">Listing</a>
              <a href="#">Blog</a>
              <a href="#">Contact</a>
            </p>
          </div>

          {/* Social icons on the right */}
          <div className="col-md-4 text-end">
  <ul className="ftco-footer-social d-flex justify-content-end p-0">
    <li className="ftco-animate me-3">
      <a href="#" data-toggle="tooltip" data-placement="top" title="Twitter">
        <FaTwitter />
      </a>
    </li>
    <li className="ftco-animate me-3">
      <a href="#" data-toggle="tooltip" data-placement="top" title="Facebook">
        <FaFacebookF />
      </a>
    </li>
    <li className="ftco-animate">
      <a href="#" data-toggle="tooltip" data-placement="top" title="Instagram">
        <FaInstagram />
      </a>
    </li>
  </ul>
</div>

        </div>

        <div className="row mt-5">
          <div className="col-md-12 text-center text-white">
            <p className="copyright">
              Copyright © {new Date().getFullYear()} All rights reserved | This template is made with <i className="ion-ios-heart" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank" rel="noopener noreferrer">Colorlib.com</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
