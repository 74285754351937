import React from 'react';

import Navbar from './Component/Navbar/Index';

import IpLocation from './Component/GetLocation/Index';
import UserIP from './Component/GetLocation/Index';
import { BrowserRouter , Routes , Route } from 'react-router-dom';
import LoginForm from './Component/LoginForm/Index';
import Home from './Component/Pages/Home/Index';
import Nav from './Component/nav';

import Footer from './Component/Footer/Index';
import AboutUs from './Component/Pages/AboutUs/Index';
import Profile from './Component/Pages/Profile/Index';
import ContactUs from './Component/Pages/ContactUs/Index';
import Blog from './Component/Pages/Blog/Index';
import ContactHome from './Component/ContactHome/Index';
function App() {
  return (
    <div>
    



{/* <ContactHome/> */}
{/* <AuthProvider> */}
        <BrowserRouter>
<Navbar/>
          
          <Routes>
            {/* <Route index element={<LoginForm />} /> */}
            <Route path="login" element={<LoginForm />} />
            <Route path="/" element={<Home/>} />
            <Route path="/AboutUs" element={<AboutUs/>} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/ContactUs" element={<ContactHome/>} />
            <Route path="/Blog" element={<Blog/>} />



          
              {/* <Route index element={<Navigate replace to="my-home" />} />
              <Route path="my-home" element={<MyHome />} />
              
                 */}
   
              

              

              
          
           
          </Routes>
        </BrowserRouter>
      {/* </AuthProvider> */}
      <Footer/>





{/* <HeroSection/>
<UserIP/>

<HomeCards/>
<BlogCard/>
<OurServices/> */}

{/* <GetInTouch/> */}

    </div>
  );
}

export default App;
