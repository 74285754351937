import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure you have Bootstrap CSS loaded
import './FaqHome.css'
const FaqHome = () => {
  const [activeIndex, setActiveIndex] = useState(0); // State to keep track of active FAQ

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); 
  };

  return (
    <div className=' faq bg-light'>
        <div className="container my-5 ">
      <div className="row">
      <div className="col-sm-4">
      <h1 className="page-title mb-4">FAQs</h1>

      </div>
        <div className="col-lg-8">
          <div className="accordion" id="accordionExample">
            {/* FAQ 1: How to add bank details */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={`accordion-button ${activeIndex === 0 ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => toggleAccordion(0)}
                >
                  How to add bank details?
                </button>
              </h2>
              <div
                id="collapseOne"
                className={`accordion-collapse collapse ${activeIndex === 0 ? 'show' : ''}`}
                aria-labelledby="headingOne"
              >
                <div className="accordion-body">
                  <strong>To add your bank details:</strong> Navigate to the "Profile" section of your account. Then, select "Bank Information" and input your bank details such as account number and routing number. Finally, click "Save" to update your details.
                </div>
              </div>
            </div>

            {/* FAQ 2: How to watch ads */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className={`accordion-button ${activeIndex === 1 ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => toggleAccordion(1)}
                >
                  How to watch ads?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className={`accordion-collapse collapse ${activeIndex === 1 ? 'show' : ''}`}
                aria-labelledby="headingTwo"
              >
                <div className="accordion-body">
                  <strong>To watch ads:</strong> Go to the "Ads" section from the main menu. Select the category of ads you are interested in. Once the ad video starts, watch it fully to earn credits. You can see the total earned credits in your account summary.
                </div>
              </div>
            </div>

            {/* FAQ 3: Payment schedule */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className={`accordion-button ${activeIndex === 2 ? '' : 'collapsed'}`}
                  type="button"
                  onClick={() => toggleAccordion(2)}
                >
                  Payment schedule
                </button>
              </h2>
              <div
                id="collapseThree"
                className={`accordion-collapse collapse ${activeIndex === 2 ? 'show' : ''}`}
                aria-labelledby="headingThree"
              >
                <div className="accordion-body">
                  <strong>Payment schedule details:</strong> Payments are processed on the 1st and 15th of every month. If your account balance exceeds the minimum payout threshold, the funds will be transferred to your designated bank account within 5-7 business days.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default FaqHome;
