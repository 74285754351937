import React from 'react';

function Profile() {
  return (
    <div>
      <section className="bg-light py-3 py-md-5 py-xl-8">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="mb-4 display-5 text-center">Profile</h2>
              <p className="text-secondary text-center lead fs-4 mb-5">
                The Profile page is your digital hub, where you can fine-tune your experience. Here's a closer look at the settings you can expect to find in your profile page.
              </p>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row gy-4 gy-lg-0">
            <div className="col-12 col-lg-4 col-xl-3">
              <div className="row gy-4">
                <div className="col-12">
                  <div className="car widget-card border-light shadow-sm">
                    <div className="card-header text-bg-primary p-3">Welcome, Ethan Leo</div>
                    <div className="card-body">
                    <div className="text-center mb-3">
  <img
    src="Images/img2.jpg"
    className="img-fluid rounded-circle"
    alt="Ethan Leo"
    style={{ width: '100px', height: '100px', marginTop: '15px' }}
  />
</div>

                      <h5 className="text-center mb-1">Ethan Leo</h5>
                      <p className="text-center text-secondary mb-4">Viewer</p>
                      <ul className="list-group list-group-flush mb-4">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <h6 className="m-0">Total Ads</h6>
                          <span>7,854</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <h6 className="m-0">Week ads</h6>
                          <span>200</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <h6 className="m-0">Today Ads</h6>
                          <span>30</span>
                        </li>
                      </ul>
                      <div className="d-grid m-0">
                        <button className="btn btn-outline-primary" type="button">More Details</button>
                      </div>
                    </div>
                  </div>
                </div>
             
                <div className="col-12">
                  <div className="card widget-card border-light shadow-sm">
                    <div className="card-header text-bg-primary">Bank Details</div>
                    <div className="card-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <span>Bank</span>
                          <span>HBL</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <span>Acc:</span>
                          <span>5689000865123</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                          <span>PayPal Acc</span>
                          <span></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* About Me Card */}
               
              </div>
            </div>

            <div className="col-12 col-lg-8 col-xl-9">
              <div className="card widget-card border-light shadow-sm">
                <div className="card-body p-4">
                  <ul className="nav nav-tabs" id="profileTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">Overview</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Profile</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="emails-tab" data-bs-toggle="tab" data-bs-target="#emails-tab-pane" type="button" role="tab" aria-controls="emails-tab-pane" aria-selected="false">Emails</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="password-tab" data-bs-toggle="tab" data-bs-target="#password-tab-pane" type="button" role="tab" aria-controls="password-tab-pane" aria-selected="false">Password</button>
                    </li>
                  </ul>
                  <div className="tab-content pt-4" id="profileTabContent">
                    <div className="tab-pane fade show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabindex="0">
                      <h5 className="mb-3">About</h5>
                      <p className="lead mb-3">Ethan Leo is a seasoned and results-driven Project Manager with a proven track record in leading successful projects...</p>
                    </div>
                    <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                      <h5 className="mb-3">Profile Details</h5>
                      <p>Email: ethan.leo@example.com</p>
                      <p>Phone: +123 456 7890</p>
                      <p>Location: New York, USA</p>
                    </div>
                    <div className="tab-pane fade" id="emails-tab-pane" role="tabpanel" aria-labelledby="emails-tab" tabindex="0">
                      <h5 className="mb-3">Emails</h5>
                      <p>Here you can manage your email settings...</p>
                    </div>
                    <div className="tab-pane fade" id="password-tab-pane" role="tabpanel" aria-labelledby="password-tab" tabindex="0">
                      <h5 className="mb-3">Change Password</h5>
                      <p>Here you can change your password...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Profile;
